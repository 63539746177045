import * as React from 'react';
import Layout from '@components/layouts/Layout';

const NotFoundPage = (props) => {
  return (
    <Layout pageTitle="Page Not Found" {...props}>
      <section className="section-secondary relative w-full h-full bg-black">
        <div className="max-w-screen-2xl mx-auto grid grid-cols-12 pt-10 xl:px-20 px-5 h-screen">
          <div className="col-span-12 pt-10 pb-5 xl:mb-20 mb-10 xl:mx-5 mt-5 xl:px-10 px-5 rounded-xl text-white text-center">
            <h1 className="font-rokkit font-bold xl:mb-7 mb-4 text-white uppercase text-center xl:text-6xl text-2xl">404 Page not found</h1>
            <p className="text-lg"> Sorry, we couldn’t find what you were looking for.</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
